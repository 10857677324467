button {
  /* background-color: #0c53a0 !important; */
  text-transform: inherit !important;
}

.page_title {
  font-size: 1.5rem !important;
  margin: 0px 0px 8px !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: #000000 !important;
}

.form_design {
  background-color: rgb(255, 255, 255);
  color: rgb(33, 43, 54);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgb(145 158 171 / 20%) 0px 0px 2px 0px,
    rgb(145 158 171 / 12%) 0px 12px 24px -4px;
  border-radius: 16px;
  z-index: 0;
  margin-top: 20px;
}

.employee-form {
  display: block;
  flex-flow: row wrap;
  width: 100%;
  position: relative;
  padding: 3% !important;
  margin: auto;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 100%;
}

.employee-form-struct {
  color: #056441;
  font-size: 16px !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  position: relative;
  overflow-wrap: break-word;
  background-clip: border-box;
  overflow: visible;
  /* height: 100%; */
}

.MuiButtonBase-root {
  border-radius: 10px !important;
}

.MuiButton-containedSuccess {
  background-color: #00ab55 !important;
}

.MuiButton-containedSuccess:hover {
  background-color: rgb(0, 123, 85) !important;
}

.button_first {
  margin-right: 15px !important;
}

@media (min-width: 768px) {
  .employee-form-struct {
    padding: 2% 5% !important;
  }

  .form_design {
    padding: 2% 3% !important;
  }

  .employee-table {
    padding: 0% 10% !important;
  }

  .employee-form-list {
    padding: 5% 5% !important;
  }
}

.employee-form-list .btn-primary {
  background: #03623f !important;
}

.rdrCalendarWrapper {
  color: #000000;
  font-size: 12px;
  position: absolute;
  z-index: 99;
}

.form-lable-name {
  font-size: 18px !important;
  color: black !important;
}

.App {
  position: relative;
  font-family: sans-serif;
  text-align: center;
  width: 100vw;
  min-height: 100vh;
  background-image: url("https://images.unsplash.com/photo-1554303867-f61340bb2cf5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1534&q=80");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  z-index: 1;
}

.App::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.2;
  z-index: -1;
}

.wizard {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  z-index: 2;
  pointer-events: all;
}

.submit-button {
  margin-top: 20px;
  text-align: right;
}

.submit-button {
  margin-top: 20px;
  text-align: right;
}

.App::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.2;
  z-index: -1;
}

.wizard {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 0;
  z-index: 2;
  pointer-events: all;
}

/* .MuiFormLabel-root {
  background-color: white;
} */

.submit-button {
  margin-top: 20px;
  text-align: right;
}

.align-editbutton {
  margin: auto !important;
  justify-content: end;
  padding: 0px 40px;
}

.profile-img {
  margin: auto !important;
}

.editicon-avatar .MuiSvgIcon-root {
  width: 30px;
  height: 30px;
  background: #e3dede;
  border: 3px solid #ffffff;
  margin-top: 120px;
  z-index: 99;
  margin-left: -55px;
  border-radius: 25px;
}

.editicon-avatar {
  background-color: #ffffff00 !important;
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%) !important;
}

.align-cancelbutton {
  margin-right: 20px !important;
}

@media (max-width: 768px) {
  .align-cancelbutton {
    margin-bottom: 10px !important;
    margin-right: 0px !important;
  }

  .form_mobile {
    margin-top: 10%;
  }
}

.profile-edit input {
  padding: 10px 4px;
}

.profile-edit {
  padding: 10px;
}

.bg-white {
  background: white;
  padding: 10px;
  border-radius: 5px;
}

.btn-primary {
  padding: 5px 30px !important;
  background: #0c54a0 !important;
  color: white !important;
  border-radius: 5px !important;
}

.chart-button-grid .btn-changed {
  padding: 5px 30px !important;
  color: white !important;
  border-radius: 5px !important;
  background: rgb(36, 112, 17) !important;
}

.button-33 {
  min-width: 22px !important;
  line-height: 0 !important;
  border-radius: 6px !important;
  white-space: nowrap !important;
  padding: 0px 8px !important;
  color: rgb(27, 128, 106) !important;
  font-size: 0.75rem !important;
  background-color: rgba(19, 226, 140, 0.16) !important;
  font-weight: 700 !important;
  text-transform: capitalize !important;
}

.avatar_title {
  margin: auto !important;
  margin-left: initial !important;
  padding: 0px 10px;
}

.avatar_name {
  font-size: 18px !important;
  font-weight: 600 !important;
  color: #056441 !important;
}

.form-lableloop {
  display: flex;
}

.form-lableloop div {
  width: 98%;
  margin-top: 15px;
}

.form-lableloop div::nth-child {
  width: 98%;
  margin-top: 10px;
}

.remove_but {
  height: 40px !important;
  margin-top: 35px !important;
  width: 200px !important;
}

/* Select Project */

.selectproject {
  background: linear-gradient(rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8));
  padding: 10px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  background-size: cover;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.selectproject .projectlogin {
  z-index: 1;
  width: 350px;
  background-color: #fff6;
  border-radius: 0.5rem;
  padding: 1rem;
  margin: 3rem 0rem;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

/* .login_button
{
  margin-right: 10px !important;
} */

.hint {
  font-size: 12px;
  text-align: right;
  margin-top: 0px;
}

.excel_button {
  text-align: right;
}

.list_advance {
  /* margin-top: 5%; */
  margin: 5% 3% 2%;
}

.text-bold {
  font-weight: 700 !important;
  /* color: #0d6243; */
}

.activatebutton {
  text-align: right !important;
}

.align_center {
  text-align: center;
}

/* purchaseorder */
.purchaseorder {
  padding: 20px;
  margin: 15px;
  background-color: #fff;
  box-shadow: 0 1px 15px 1px rgb(62 57 107 / 7%);
  border-radius: 0.35rem;
  margin-bottom: 50px;
}

.purchaseorder .pomargin {
  margin: 0px;
}

.purchaseorder .border1 {
  border: 1px solid;
}

.purchaseorder .table_padding {
  height: 70px;
}

.purchaseorder .popading {
  padding: 10px 20px;
}

.purchaseorder .table_po {
  border: 1px solid;
}

.purchaseorder .po_table {
  padding: 10px;
}

.purchaseorder .po_leble input {
  padding: 6px;
  font-size: 12px !important;
}

.po_lebleterm label {
  padding: 6px;
  font-size: 12px !important;
  line-height: 0px;
}

.po_lebleterm .MuiSelect-select {
  padding: 8px;
  font-size: 12px !important;
}

.purchaseorder .po_leble .MuiSelect-select {
  padding: 6px;
  font-size: 12px !important;
}

.purchaseorder .lable_text {
  margin: auto;
}

.purchaseorder .po_leble {
  margin-bottom: 5px;
}

.purchaseorder .po_leble p {
  font-size: 12px !important;
}

.purchaseorder th {
  background-color: #e8e8e8 !important;
  color: black !important;
}

.purchaseorder .add_button span {
  margin: 0px;
}

.purchaseorder .add_button button {
  color: #ffffff;
  background: #2e7d32;
}

.purchaseorder .cancel_button span {
  margin: 0px;
}

.purchaseorder .cancel_button button {
  color: #ffffff;
  background: #d32f2f;
  text-align: center;
}

.table_padding_40 {
  height: 40px;
}

.tbody_col tr:nth-child(even) {
  background: #f4f4f4;
}

.tbody_col td {
  color: black;
  font-weight: 600;
  text-align: right;
  font-size: 12px;
}

.tbody_col p {
  font-size: 12px !important;
}

/* .form_control .MuiSelect-select{
  padding: 8px;
  font-size: 12px;
} */

/* Payment List */
.drop_table th {
  background-color: #ffffff !important;
  color: #000000 !important;
}

/* .MuiDialog-paperWidthSm {
  width: 500px;
  padding: 50px;
}

@media (max-width: 768px) {
  .MuiDialog-paperWidthSm {
    width: 500px;
    padding: 0px;
  }
} */

.useradmin {
  background-color: #f4f4f4;
  padding: 20px !important;
  border-radius: 10px;
  margin-top: 20px !important;
}

.title_add h3 {
  padding: 0px 20px !important;
  margin: 0px !important;
}

.info_details {
  padding: 20px;
}

.info_details .companyna {
  color: #0c54a0;
}

.companyna {
  color: #0c54a0;
}

.info_details p {
  padding: 3px 0px;
  font-size: 14px;
}

.imgtd {
  vertical-align: bottom;
}

.table_pdf {
  padding-top: 20px;
}

.table_pdf th {
  background-color: #ebeef1 !important;
  color: #000000 !important;
  border: 1px solid;
}

.title_bold {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.email_menu {
  font-size: 14px !important;
  transition: background-color 0.3s ease-in-out !important;
  color: #C70039 !important;
  /* padding: 8px !important; */
}
.email_menu:hover {
  background-color: #FCC8D1 !important;
}

.activate_text {
  font-size: 18px !important;
  padding: 5px !important;
  margin-bottom: 30px !important;
  background-color: #d32f2f;
  color: white;
  width: 70%;
  margin-right: auto !important;
  margin-left: auto !important;
}

.align_button {
  margin: auto !important;
}

.icon_coloraccept {
  color: #00ab55;
  /* margin-left: 20px !important; */
}

.icon_colordeclined {
  color: #d32f2f !important;
  /* margin-left: 20px !important; */
}

.icon_colorpending {
  color: orange !important;
  /* margin-left: 20px !important; */
}

.icon_colorinfo {
  color: rgb(0, 140, 255) !important;
  /* margin-left: 20px !important; */
}

.button_yellow {
  background-color: rgba(255, 166, 0, 0.068) !important;
  color: orange !important;
  border: 1px solid orange !important;
}

.action_button {
  padding: 2px 5px !important;
  color: green !important;
  background: #0080001a !important;
  border-radius: 8px !important;
}

.correct {
  color: #00ab55 !important;
}

.wrong {
  color: #d32f2f !important;
}

.hIWEKa .iSAVrt {
  background: #eee2d97a !important;
  border-radius: 10px 10px 0px 0px !important;
  font-weight: 600 !important;
}

.small_table .glyQNg div {
  justify-content: center !important;
}

.small_table .dwPlXY div {
  justify-content: center !important;
}

.span_text {
  color: #9c27b0 !important;
  font-weight: 600;
}

.full-day-attendance {
  color: #31774c;
}

.half-day-attendance {
  color: #15ee68;
}

.leave-attendance {
  color: #982323;
}

.nodata-attendance {
  color: #0c54a0;
}

.sunday-attendance {
  color: #Fdbe02;
}

.table-title {
  font-size: 1.2rem !important;
  margin: 0px 0px 8px !important;
  font-weight: 700 !important;
  line-height: 1.5 !important;
  color: #050505 !important;
}