.login-background {
  background-color: #ffffff;
  display: grid;
  align-items: start;
}

.logo-center {
  text-align: center;
}

.login-background-img {
  height: auto;
  width: 350px;
}

.inner-login-box {
  z-index: 1;
  height: 450px;
  width: 40%;
  background-color: #fff6;
  border-radius: 0.5rem;
  padding: 3rem 1rem;
  margin: 3rem 0rem;
  box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}
.inner-login-admin
{
  height:480px;
}
.inner-login-login
{
  height:400px;
}
@media (max-width: 768px) {
  .inner-login-box {
    width: 100% !important;
    padding: 5px !important;
  }
  .login-background-img {
    display: none;
  }
}
.topbar-heading {
  margin-top: -80px;
}

.padding-10 {
  padding: 10px !important;
}

.full-height {
  height: 100vh;
}

.color-white {
  color: white;
}

.empty-space-30 {
  margin-top: 30px;
}

.empty-space-20 {
  margin-top: 20px;
}

.empty-space-40 {
  margin-top: 40px;
}

a {
  color: #0d6efd;
  text-decoration: none;
  background-color: transparent;
  font-weight: 600 !important;
  font-size: 0.875rem;
}

.logo-image {
  width: 100px;
  padding-top: 30px;
}

.MuiFormControl-root.MuiTextField-root.form-control.is-invalid.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.MuiFormControl-root.MuiTextField-root.form-control.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.invalid-feedback {
  color: red;
  font-size: 13px !important;
}

.logo-center {
  padding-top: 10%;
}

.login-center::after {
  content: "";
  background-size: cover;
  background-repeat: no-repeat;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.075;
  position: absolute;
}

.login-center {
  background: linear-gradient(rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), url("../../assets/images/login/login-bachground.jpg"); 
  padding: 10px !important;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  justify-content: center;
  background-size: cover;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
}

.padding-10 {
  padding: 10px !important;
}

.full-height {
  height: 100vh;
}

.color-white {
  color: white;
}

.empty-space-30 {
  margin-top: 30px;
}

.login-text-background {
  background:linear-gradient(195deg, #035aa7, #0d52a2);
  box-shadow: 0 4px 20px 0 rgb(0 123 255 / 14%),
    0 7px 10px -5px rgb(0 82 234 / 40%);
  margin: -4rem -1rem 2rem;
  padding: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  color: #fff;
}
.userlogin .login-text-background {
  margin-right: 10px;
  margin-left: 10px;
}

a {
  color: #0d6efd;
  text-decoration: none;
  background-color: transparent;
  font-weight: 600 !important;
  font-size: 0.875rem;
}

.logo-image {
  width: 200px;
}

.MuiFormControl-root.MuiTextField-root.form-control.is-invalid.css-1u3bzj6-MuiFormControl-root-MuiTextField-root,
.MuiFormControl-root.MuiTextField-root.form-control.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  width: 100%;
}

.invalid-feedback {
  color: red;
  font-size: 13px !important;
}

.topbar-login {
  background-color: #0c529d00;
  padding: 10px;
  font-size: 16px;
}

.css-11yukd5-MuiTabs-indicator {
  background-color: #ffffffcc !important;
}

.topbar-login button {
  font-size: 18px;
  color: white;
}

.mpin-text {
  padding: 50px 30px !important;
  width: 55%;
  margin: auto;
}

.mpin-text .css-1ld3b9g-MuiGrid-root > .MuiGrid-item {
  padding-left: 10px;
  padding-right: 10px;
}
.otp-field {
  width: 60px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
  height: 40px;
}
